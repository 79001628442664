import { ShopifyOnlineStore, ShopifyInstalledTheme, CustomizerIntegrationType } from '@packages/types'
import { useQuery } from '@tanstack/react-query'
import React, { useEffect, useState } from 'react'

import { Page } from 'cms/layout'
import { useShopifyProxyService } from 'cms/onlineStores/hooks'
import { redirectToShopifyIntegration } from 'cms/onlineStores/utils'
import { Button, Card, InputField, Label, Select, Tag } from 'common/components'

import IntegrationCardSection from './IntegrationCardSection'
import ShopifyAppEmbedSteps from './ShopifyAppEmbedSteps'

interface AppEmbedSectionProps {
  onlineStore: ShopifyOnlineStore
  themes: ShopifyInstalledTheme[] | undefined
  isLoading?: boolean
}

const AppEmbedSection = ({ onlineStore, themes, isLoading }: AppEmbedSectionProps) => {
  const [selectedTheme, setSelectedTheme] = useState<ShopifyInstalledTheme>()
  const shopifyProxyService = useShopifyProxyService()

  const { data: theme, isLoading: isLoadingThemeInfos } = useQuery(
    [...shopifyProxyService.fetchTheme.queryKeys, selectedTheme?.id],
    () => shopifyProxyService.fetchTheme(onlineStore.id, String(selectedTheme!.id)),
    { enabled: !!selectedTheme?.id }
  )

  const isAppEmbedDisabled = !theme?.appEmbed || theme.appEmbed.disabled === true

  const options = (themes || []).map(({ id, role, name }) => {
    if (role === 'main')
      return {
        value: id,
        label: (
          <span>
            {name}
            <Tag className="text-neutral-900 bg-tertiary-green-75 ml-2">Live</Tag>
          </span>
        ),
      }

    return { value: id, label: name }
  })

  useEffect(() => {
    if (themes && !selectedTheme) setSelectedTheme(themes.find(({ role }) => role === 'main'))
  }, [themes])

  return (
    <Page.Section>
      <Page.Aside title="App embed" description="Settings for the Kickflip app integration with Shopify" />
      <Page.Content>
        <Card className="flex flex-1 flex-col">
          <Card.Section className="md:flex-col space-y-6">
            {isLoading && <div className="animate-pulse h-8 bg-neutral-75 rounded" />}
            {!isLoading && (
              <InputField>
                <Label>Shopify theme</Label>
                <Select
                  aria-label="theme-settings"
                  id="theme-settings"
                  name="theme-settings"
                  placeholder=""
                  menuPortalTarget={document.body}
                  styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                  value={selectedTheme ? options?.find(option => option.value === selectedTheme.id) : null}
                  options={options}
                  onChange={option => setSelectedTheme(themes?.find(theme => theme.id === option?.value))}
                />
              </InputField>
            )}
          </Card.Section>
          <Card.Separator />
          {isLoading || isLoadingThemeInfos ? (
            <Card.Section className="space-x-6 items-center">
              <div className="animate-pulse h-60 bg-neutral-75 rounded w-full" />
            </Card.Section>
          ) : (
            <>
              {isAppEmbedDisabled && theme && (
                <Card.Section className="space-x-6 items-center">
                  <ShopifyAppEmbedSteps />
                </Card.Section>
              )}
              {!isAppEmbedDisabled && theme?.appEmbed?.settings?.integration_type && (
                <Card.Section className="space-x-6">
                  <IntegrationCardSection
                    integrationType={theme.appEmbed.settings.integration_type as CustomizerIntegrationType}
                  />
                </Card.Section>
              )}
              <Card.Footer>
                <Button onClick={() => redirectToShopifyIntegration({ onlineStore, theme })}>Edit App Embed</Button>
              </Card.Footer>
            </>
          )}
        </Card>
      </Page.Content>
    </Page.Section>
  )
}

export default AppEmbedSection
